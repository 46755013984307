export const checkError = async (response) => {
    if (!response.ok)
    {
        throw await response.json();
    }

    return response.json();
}

export const checkAuthenticationError = (error) => {
    if (error.code === 401) {
        console.log('------------401')
        localStorage.removeItem('llm-authorization');
        return true;
    }
    return false;
}