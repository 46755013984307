// src/App.js

//modules
import React from 'react';
//components
import LandingPage from './LandingPage';
//styles
import './App.css';
import 'rsuite/dist/rsuite.min.css';

function App() {
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;
