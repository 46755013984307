// src/LandingPage.js

//modules
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
//components
import LoginPopup from './components/LoginPopup/LoginPopup';
import GptResponseItem from './components/GptResponseItem/GptResponseItem';
import SideNav from './components/SideNav/SideNav';
//services
import ResponseServices from './services/ResponseServices';
import ErrorHandler from './errorhandlers';
import threadServices from './services/ThreadServices';
import timeUtils from './utils/TimeUtils';

const initialContent = [];

const LandingPage = () => {
  const authorization = localStorage.getItem('llm-authorization');
  const [threadId, setThreadId] = useState(null);
  const [inputText, setInputText] = useState('');
  const [responseData, setResponseData] = useState(initialContent);
  const [loading, setLoading] = useState(false);
  const [loginPopup, setLoginPopup] = useState(!authorization);
  const [sidenavExpanded, setSidenavExpanded] = useState(false);
  const [responseTimer, setResponseTimer] = useState(0);
  const responseTimerRef = React.useRef(0);

  const toggleSideNav = () => {
    setSidenavExpanded(prevState => !prevState);
  }

  const handleCloseLoginPopup = () => {
    setLoginPopup(false);
  }

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  //to open a thread from history
  const handleThreadClick = async (threadId) => {
    try {
      setThreadId(threadId);
      const payload = {
        threadId: threadId,
        authorization: authorization
      }
      const response = await threadServices.getThreadData(payload);
      const data = await ErrorHandler.checkError(response);
      setResponseData((prevData) => {
        const newData = data.history.map(item => {
          const time = timeUtils.getformattedTime(item.createdAt);
          if (item.role === "user")
          {
            return {
              role: item.role,
              question: item.content,
              time: time
            }
          }
          else
          {
            return {
              role: item.role,
              response: {
                answer: item.content,
                bq_result: item.bq_response,
                time: time,
                id: item._id,
              }
            }
          }
        });
        return newData;
      })
    } catch (error) {
      if (ErrorHandler.checkAuthenticationError(error)) {
        setLoginPopup(true);
      }
      toast.error(error.message);
    }
  }

  // get response from model for the question
  const handleRunButtonClick = async () => {
    const timer = setInterval(() => {
      setResponseTimer(prevState => prevState + 1);
    }, 1000)
    try {
      setLoading(true);
      const currentQuestion = inputText;
      setInputText('');
      const currentTime = timeUtils.getformattedTime(timeUtils.getCurrentTime());
      setResponseData(prevData => [...prevData, { role: "user", question: currentQuestion, time: currentTime}, { role: "assistant" }]);

      const payload = {
        authorization: authorization,
        threadId: threadId, 
        inputText: inputText
      }
      const response = await ResponseServices.getGptResponse(payload);
      const data = await ErrorHandler.checkError(response);

      setResponseData(prevData => {
        const newData = [...prevData];
        if (newData.length > 0) {
          const timeTaken = responseTimerRef.current;
          const currentTime = timeUtils.getformattedTime(timeUtils.getCurrentTime());
          newData[newData.length - 1] = { role: "assistant", response:{ ...data, response_time: timeTaken, time: currentTime} };
        }
        return newData;
      });
    } catch (error) {
      if (ErrorHandler.checkAuthenticationError(error)) {
        setLoginPopup(true);
      }
      toast.error(error.message);
      setResponseData(prevData => {
        if (prevData.at(prevData.length - 1)?.role === "assistant") return [...prevData.slice(0, prevData.length - 1)];
        return [...prevData];
      })
    } finally {
      setLoading(false);
      clearInterval(timer);
      setResponseTimer(0);
    }
  };

  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === "Enter") {
      handleRunButtonClick();
    }
  };

  useEffect(() => {
    // Call the first API when the component mounts to create new thread
    if (threadId === null && !!authorization) {
      const generateNewthread = async () => {
        try {
          const response = await threadServices.createNewThread(authorization);
          const data = await ErrorHandler.checkError(response);
          setThreadId(data.thread_id);
        } catch (error) {
          if (ErrorHandler.checkAuthenticationError(error)) {
            setLoginPopup(true);
          }
          toast.error(error.message);
        }
      }
      generateNewthread();
    }
  }, [threadId, authorization]);

  useEffect(() => {
    responseTimerRef.current = responseTimer;
  }, [responseTimer]);

  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  }, [responseData])
  
  useEffect(() => {
    const handleResize = () => {
      const element = document.getElementById('text-input-area');
      if (element) {
        element.style.height = 'auto';
        const newHeight = element.scrollHeight + 'px';
        element.style.height = newHeight;
      }
    };
    handleResize();
  }, [inputText]);

  return (
    <div className='gpt-page-container'>
      <div className="header">
        <div className="logo-area">
          <img
            src="/logo.png"
            width={48}
            height={48}
            alt="Logo"
          />
        </div>
        <h4 className='analytix-text'>AnalytiX</h4>
      </div>
      <div className="announcement-bar">
        <p>ShopDeck AnalytiX - Business ChatBot</p>
      </div>
        <div className='page-body'>
        <SideNav isExpanded={sidenavExpanded} toggleSidenav={toggleSideNav} threadClick={handleThreadClick}/>
          <div className={sidenavExpanded ? 'page-content-expanded' : 'page-content'}>
            <div className={`chat-container ${sidenavExpanded ? `chat-container-expanded` :  `chat-container-shrinked`}`}>
              {responseData && (responseData.map((response, index) => (
                <div key={`gpt-item${index}`} className='gpt-item'>
                  {
                    response.role === "user" && 
                    <div className='question-container'>
                      <div className='chat-item'>
                          <div className='question-text'>
                            {response.question}
                          </div>
                          {
                            response.time && 
                            <div className='current-time-container'>
                              {response.time}
                            </div>
                          }
                      </div>
                      <div className='gpt-icon-container'>
                        <img className='programmer-icon' src='/programmer.png' alt='user'/>
                      </div>
                    </div>
                  }
                  {
                    response.role === "assistant" &&
                    <GptResponseItem responseTimer={responseTimer} gptResponse={response} />
                  }
                </div>
              )))}
              {
                responseData.length === 0 &&
                <div className='initial-display'>
                    <img src='background-loader.gif' alt='Loading...' height={'30%'}/>
                </div>
              }
            </div>
            <div className='chat-input-container'>
              <div id='input-area-box' className='input-area-container'>
                <textarea className='input-area' id='text-input-area' placeholder='Ask Question!' value={inputText} onKeyDown={handleKeyDown} onChange={handleInputChange}/>
                <button className={loading ? 'run-button-disabled' : 'run-button'} onClick={handleRunButtonClick} disabled={loading}>
                  <img className='upload-image' src={loading ? '/loading.png' : '/upload.png'} alt='upload'/>
                </button>
              </div>
            </div>
          </div>
        </div>
      <ToastContainer />
      {loginPopup && <LoginPopup handleClose={handleCloseLoginPopup} />}
      </div>
  );
};

export default LandingPage;
