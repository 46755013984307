//modules
import React from 'react'

//styles
import style from './ExpandButton.module.css';
import { Tooltip, Whisper } from 'rsuite';

const ExpandButton = (props) => {
  const { handleCollapse, handleExpand, expanded } = props;

  return (
    <div className={style.CopyButtonContainer}>
      {expanded ? 
        <Whisper followCursor speaker={<Tooltip>Collapse</Tooltip>}>
          <img src='/up-arrow.png' className={style.CollapseButton} alt='up' onClick={handleCollapse} />
        </Whisper>
        :
        <Whisper followCursor speaker={<Tooltip>Expand</Tooltip>}>
          <img src='/down-arrow.png' className={style.ExpandButton} alt='up' onClick={handleExpand} />
        </Whisper>
      }
    </div>
  )
}

export default ExpandButton