import React from 'react'
import style from './ReactionCard.module.css';
import { toast } from 'react-toastify';
import { Tooltip, Whisper } from 'rsuite';
import ResponseServices from '../../services/ResponseServices';
import ErrorHandler from '../../errorhandlers';

const ReactionCard = (props) => {
  const { chatId, isVisible } = props;
  const [isLiked, setIsLiked] = React.useState(false);
  const [isDisliked, setIsDisliked] = React.useState(false);

  const handleLike = () => {
    handleReaction(true);
  }

  const handleDislike = () => {
    handleReaction(false);
  }

  const handleReaction = async (isLiked) => {
    try {
      const payload = {
        isLiked: isLiked,
        chatId: chatId
      }
      const response = await ResponseServices.reactToResponse(payload);
      await ErrorHandler.checkError(response);
      setIsLiked(isLiked);
      setIsDisliked(!isLiked);
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
      !!isVisible &&
      <div className={style.ReactionCardContainer}>
        {!isLiked && 
          <Whisper followCursor speaker={<Tooltip>Like</Tooltip>}>
              <img className={style.ReactionImage} src='/like.png' alt='like' onClick={handleLike} height='18px'/>
          </Whisper>
        }
        {!isDisliked && 
          <Whisper followCursor speaker={<Tooltip>Dislike</Tooltip>}>
              <img className={style.ReactionImage} src='/dislike.png' alt='dislike' onClick={handleDislike} height='18px'/>
          </Whisper>
        }
    </div>
  )
}

export default ReactionCard;