//modules
import React from 'react'
import classNames from 'classnames/bind'
import { toast } from 'react-toastify';
//components
import { Placeholder } from 'rsuite';
//style
import style from './ThreadHistory.module.css'
import threadServices from '../../services/ThreadServices';
import ErrorHandler from '../../errorhandlers';

const cx = classNames.bind(style);

const ThreadHistory = (props) => {
    const { handleThreadClick } = props;
    const authorization = localStorage.getItem('llm-authorization');
    const [threads, setThreads] = React.useState([]);
    
  React.useEffect(() => {
    const getThreadHistory = async () => {
        try {
          const response = await threadServices.getThreadHistory(authorization);
          const data = await ErrorHandler.checkError(response);
          let groupingByDate = Object.values(
            data.threads.reduce((acc, current) => {
                    acc[current.timestamp] = acc[current.timestamp] ?? [];
                    acc[current.timestamp].push(current);
                    return acc;
                }, {})
          );
          setThreads(groupingByDate);
        } catch (error) {
          toast.error(error.message);
        }
    }
    getThreadHistory();
  }, [authorization]);
  return (
    <div className={cx('thread-history-container')}>
          {
            threads.length === 0 &&
            <Placeholder.Paragraph  active rows={4}/>
          }
          {
              threads.map((threadDayGroup, index) => (
                  <div className={cx('thread-day-group')} key={`thread-day-group${index}`}>
                      <div className={cx('day-container')}>
                          <p className={cx('day-text')}>{threadDayGroup.at(0).timestamp}</p>
                      </div>
                     { threadDayGroup.map((thread, index) => (
                          <div key={`thread-${index}`} className={cx('thread-item')} onClick={() => handleThreadClick(thread.thread_id)}>
                              <img className={cx('chat-icon')} alt='chat' src='/chat-icon.png'></img>
                              <p className={cx('thread-title')}>
                                {thread.content}
                              </p>
                          </div>
                      ))}
                  </div>
              ))
          }
    </div>
  )
}

export default ThreadHistory