//modules
import React from 'react'

//rsuite
import { Modal, Button, Form, ButtonToolbar, Schema } from 'rsuite'
import UnvisibleIcon from '@rsuite/icons/Unvisible';
import VisibleIcon from '@rsuite/icons/Visible';

//style
import style from './LoginPopup.module.css'

const noSpacesRule = (message) => {
    return StringType().addRule(
      (value, data ) => {
            return !(/\s/.test(value));
        }, message
    );
  };

//Form Validation Schema
const { StringType } = Schema.Types;
const model = Schema.Model({
    name: noSpacesRule("Username can't have spaces!").isRequired('Name is required!'),
    password: noSpacesRule("Password can't have spaces!").isRequired('Please enter Password!')
});

const LoginPopup = (props) => {
    const { handleClose } = props;

    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [username, setUserName] = React.useState("");
    const [password, setPassword] = React.useState("");

    const handleUserNameInput = (value) => {
        setUserName(value);
    }

    const handlePasswordInput = (value) => {
        setPassword(value);
    }

    const handleSubmit = () => {
        localStorage.setItem('llm-authorization', `${username} ${password}`);
        handleClose();
    }

    return (
        <Modal backdropClassName='login-modal-backdrop' className={style.ModalContainer} open>
            <Modal.Body className={style.ModalBody}>
                <h2 className={style.LoginHeading}>Login!</h2>
                <Form model={model} onSubmit={handleSubmit}>
                    <Form.Group controlId="name">
                    <Form.ControlLabel>Username</Form.ControlLabel>
                    <Form.Control value={username} name="name" onChange={handleUserNameInput}/>
                    </Form.Group>
                    <Form.Group controlId="password">
                        <div className={style.PasswordLabelContainer}>
                            <Form.ControlLabel>Password</Form.ControlLabel>
                            {
                                passwordVisible ?
                                <VisibleIcon className={style.PasswordIcon} onClick={() => setPasswordVisible(false)} /> :
                                <UnvisibleIcon className={style.PasswordIcon} onClick={() => setPasswordVisible(true)} />
                            }
                        </div>
                    <Form.Control value={password} name="password" type={passwordVisible ? "text": "password"} onChange={handlePasswordInput} autoComplete="off"/>
                    </Form.Group>
                    <Form.Group>
                    <ButtonToolbar className={style.ButtonContainer}>
                        <Button className={style.SubmitButton} size='lg' type='submit' style={{backgroundColor: "rgb(75, 75, 75)", color: "white"}}>Submit</Button>
                    </ButtonToolbar>
                    </Form.Group>
                </Form>
            </Modal.Body>
      </Modal>
    )
}

export default LoginPopup