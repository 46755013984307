//modules
import React from 'react'
//rsuite
import CopyIcon from '@rsuite/icons/Copy';
import CheckIcon from '@rsuite/icons/Check';
//styles
import style from './CopyButton.module.css';
import { Tooltip, Whisper } from 'rsuite';

const CopyButton = (props) => {
  const { copyText, toolTip } = props;
  const [copied, setCopied] = React.useState(false);
  
  const handleCopy = () => {
    navigator.clipboard.writeText(copyText);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    },3000)
  }
  return (
    <div className={style.CopyButtonContainer}>
      {copied ? 
        <Whisper followCursor speaker={<Tooltip>Copied</Tooltip>}>
          <CheckIcon />
        </Whisper>
        :
        <Whisper followCursor speaker={<Tooltip>{toolTip || "Copy"}</Tooltip>}>
          <CopyIcon className={style.CopyButton} onClick={handleCopy}/>
        </Whisper>
      }
    </div>
  )
}

export default CopyButton