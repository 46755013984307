export const createNewThread = async (authorization) => {
    return await fetch(`https://dashboard.preprod.blitzshopdeck.in/api/gpt/text-to-sql/?auth=${authorization}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authorization,
        }
    })
}

export const getThreadHistory = async (authorization) => {
    return await fetch(`https://dashboard.preprod.blitzshopdeck.in/api/gpt/text-to-sql/threads-history/?auth=${authorization}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
}

export const getThreadData = async (payload) => {
  const { threadId, authorization } = payload;
  return await fetch(`https://dashboard.preprod.blitzshopdeck.in/api/gpt/text-to-sql/threads-history/${threadId}/?auth=${authorization}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authorization,
    }
  })
}