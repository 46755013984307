//modules
import React from 'react'
import classNames from 'classnames/bind';
//components
import ThreadHistory from '../ThreadHistory/ThreadHistory';
//rsuite
import { Tooltip, Whisper } from 'rsuite';
//styles
import style from './SideNav.module.css';

const cx = classNames.bind(style);

const SideNav = (props) => {
    const { isExpanded, toggleSidenav, threadClick } = props;
    return (
        <div className={cx(['side-nav', isExpanded ? 'side-nav-expanded' : 'side-nav-collapsed'])}>
            <div className={cx('header-container')}>
                <div className={cx(['button-container', isExpanded ? 'button-container-expanded' : 'button-container-collapsed'])}>
                    {isExpanded && <p className={cx('thread-history-header')}>History</p>}
                    <Whisper followCursor speaker={<Tooltip>{isExpanded ? "Close History": "Show History"}</Tooltip>}>
                        <div className={cx('history-icon')} onClick={toggleSidenav}></div>
                    </Whisper>
                </div>
            </div>
            {isExpanded && <ThreadHistory handleThreadClick={threadClick}/>}
        </div>
  )
}

export default SideNav