export const getGptResponse = async (payload) => {
    const { authorization, threadId, inputText } = payload;
    return await fetch(`https://dashboard.preprod.blitzshopdeck.in/api/gpt/text-to-sql/query/?question=${inputText}&thread_id=${threadId}&auth=${authorization}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authorization,
        }
      })
}

export const reactToResponse = async (payload) => {
  const { isLiked, chatId } = payload;
  return await fetch(`https://dashboard.preprod.blitzshopdeck.in/api/gpt/text-to-sql/chat/${chatId}/feedback`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ is_liked: isLiked })
  })
}