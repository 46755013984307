//modules
import React from 'react'
//components
import CopyButton from '../CopyButton/CopyButton';
import ReactionCard from '../ReactionCard/ReactionCard';
//style
import './GptResponseItem.css'
import { Table } from 'rsuite';
import ExpandButton from '../ExpandButton/ExpandButton';

const GptResponseItem = (props) => {
  const { gptResponse, responseTimer } = props;
  const [isHovered, setIsHovered] = React.useState(false);
  const [columnKeys, setcolumnKeys] = React.useState([]);
  const [expandedText, setExpandedText] = React.useState(false);

  const handleExpandText = () => {
    setExpandedText(true);
  }

  const handleCollapseText = () => {
    setExpandedText(false);
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // Applied Inline CSS for Rsuite Table as some issues
  const headerStyle = {
    backgroundColor: "#333",
    color: "#fff",
  };

  const cellStyle = {
    backgroundColor: "#444",
    color: "#fff",
    borderColor: "rgb(100, 100, 100)",
  };

  React.useEffect(() => {
    if (!gptResponse?.response?.bq_result) {
      return;
    }
    const rowItems = gptResponse?.response.bq_result;
    if (!Array.isArray(rowItems)) {
      return;
    }

    if (!rowItems.length) {
      return;
    }

    const firstRow = rowItems[0];
    setcolumnKeys(Object.keys(firstRow))
  }, [gptResponse]);

  return (
    <div className='response-container'>
        <div className='gpt-icon-container'>
            <img className='gpt-icon' src='/logo.png' alt='gpt'/>
        </div>
        {!!gptResponse.response ?
            <div className='response-wrapper' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className='response-item'>
              <div className='chat-gpt-response-container'>
                {gptResponse.response.answer.at(0) === "<" ? 
                  <div className={(!gptResponse.response.bq_result || expandedText) ? 'chat-gpt-html-response': 'single-line-chat-gpt-response'} dangerouslySetInnerHTML={{ __html: gptResponse.response.answer}}></div>
                :
                  <pre className={(!gptResponse.response.bq_result || expandedText) ? 'chat-gpt-response': 'single-line-chat-gpt-response'}>{gptResponse.response.answer.replace(/\\n/g, '\n')}</pre>
                }
                <div className='copy-button-container'>
                  {isHovered && !!gptResponse.response.bq_result && <ExpandButton expanded={expandedText} handleExpand={handleExpandText} handleCollapse={handleCollapseText} />}
                </div>
            </div>
            {!!gptResponse.response.bq_result && (
              <>
                {!Array.isArray(gptResponse.response.bq_result) ? (
                  <>
                  <br />
                  <div className='bq-response-header'>
                    <h4 className='bq-response-lable'>BQ Response</h4>
                    <div className={'copy-button-container'}>
                      {isHovered && <CopyButton toolTip={"Copy BQ Response"} copyText={`\`\`\`${JSON.stringify(gptResponse.response.bq_result, null, 2)}\`\`\``} />}
                    </div>
                  </div>
                  <div className='bq-response-container'>
                    <pre className='bq-response'>{JSON.stringify(gptResponse.response.bq_result, null, 2)}</pre>
                  </div>
                  </>
                ) : (
                  <>
                  <br />
                  <div className='bq-response-header'>
                    <h4 className='bq-response-lable'>BQ Response</h4>
                    <div className='copy-button-container'>
                      {isHovered && <CopyButton toolTip={"Copy BQ Response"} copyText={`\`\`\`${JSON.stringify(gptResponse.response.bq_result, null, 2)}\`\`\``} />}
                    </div>
                  </div>
                    <div className="response-table-container">
                      <Table
                        height={500}
                        autoHeight = {gptResponse.response.bq_result.length <= 10}
                        affixHeader
                        affixHorizontalScrollbar
                        hover={false}
                        data={gptResponse.response.bq_result}
                        rowClassName = {"response-table-row"}
                      >
                        {columnKeys.map((key) => {
                          return (
                            <Table.Column key={key} flexGrow={1} minWidth={150} resizable>
                              <Table.HeaderCell style={headerStyle}>{key}</Table.HeaderCell>
                              <Table.Cell style={cellStyle} wordWrap dataKey={key}>
                                {(rowData) => String(rowData[key])}
                              </Table.Cell>
                            </Table.Column>
                          );
                        })}
                      </Table>
                    </div>
                  </>
                )}
              </>
            )}
            </div>
            <div className='reaction-container'>
              {gptResponse.response.id && <ReactionCard isVisible={isHovered} chatId={gptResponse.response.id} />}
              {(!isHovered || !gptResponse.response.id) &&
                <div className='timer-container'>
                  {
                    gptResponse.response.time && 
                    <p className='response-timer-text'>{gptResponse.response.time}</p>
                  }
                  {
                    gptResponse.response.time && gptResponse.response.response_time &&
                    <div className='timer-divider'></div>
                  }
                  {
                    gptResponse.response.response_time && 
                    <>
                      <img className='response-timer-icon' src='timer.png' alt='time' />
                      <p className='response-timer-text'>{gptResponse.response.response_time}s</p>
                    </>
                  }
                </div>
              }
            </div>
          </div>
          :
          <div className='response-loader-container'>
            <img className='response-loader-image' src='background-loader.gif' alt='Loading...' height={'40px'} />
            <div className='timer-container'>
              <img className='response-timer-icon' src='timer.png' alt='time' />
              <p className='response-timer-text'>{responseTimer}s</p>
            </div>
          </div>
        }
    </div>
  )
}

export default GptResponseItem;